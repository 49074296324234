import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Session from '@/types/Session';

interface SessionState
{
	sessionData: Session | null;
	sessions: any[];
	items: any[];
	charts: any[];
	lists: any[];
	monitors: any[];
	reports: any[];
	workflows: any[];
	supportState: any;
	plan: string | null;
	plan_info?: any;
	activeSessionIndex: number;

	sessionsSucceeded?: boolean;
	watchlistsSucceeded?: boolean;
}

const initialState: SessionState = {
	sessionData: null,
	sessions: [],
	items: [],
	charts: [],
	lists: [],
	monitors: [],
	reports: [],
	workflows: [],
	supportState: null,
	plan: null,
	activeSessionIndex: -1
};

export const sessionSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setSessionData: (state, action: PayloadAction<Session | null>) =>
		{
			return { ...state, sessionData: action.payload };
		},
		setSessions: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, sessions: action.payload };
		},
		setItems: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, items: action.payload };
		},
		setCharts: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, charts: action.payload };
		},
		setLists: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, lists: action.payload };
		},
		setMonitors: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, monitors: action.payload };
		},
		setReports: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, reports: action.payload };
		},
		setWorkflows: (state, action: PayloadAction<any[]>) =>
		{
			return { ...state, workflows: action.payload };
		},
		setPlan: (state, action: PayloadAction<string | null>) =>
		{
			return { ...state, plan: action.payload };
		},
		setPlanInfo: (state, action: PayloadAction<any>) =>
		{
			return { ...state, plan_info: action.payload };
		},
		setSupportState: (state, action: PayloadAction<any>) =>
		{
			return { ...state, supportState: action.payload };
		},
		setActiveSessionIndex: (state, action: PayloadAction<number>) =>
		{
			return { ...state, activeSessionIndex: action.payload };
		},

		setSessionsSucceeded: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, sessionsSucceeded: action.payload };
		},
		setWatchlistsSucceeded: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, watchlistsSucceeded: action.payload };
		}
	}
});

export const {
	setSessionData,
	setSessions,
	setItems,
	setLists,
	setCharts,
	setReports,
	setWorkflows,
	setPlan,
	setPlanInfo,
	setSupportState,
	setActiveSessionIndex,
	setMonitors,
	setSessionsSucceeded,
	setWatchlistsSucceeded
} = sessionSlice.actions;

export default sessionSlice.reducer;
export type { SessionState };
