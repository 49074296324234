type Entity = {
	id: string;
	name: string;
	name_variants: string[] | null | undefined;
	ticker?: string;
	nct_id?: string;
};

type ValueEntity = {
	value: string;
	name_variants: string[] | null | undefined;
};

function variantFilterRelevance(entity: Entity, filter: string, strip = false): number
{
	if (!filter) return 1;
	if (!entity || !entity.name) return 0;

	const normalize = (str: string): string => (strip ? str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : str.toLowerCase());

	const normalizedFilter = normalize(filter);
	const normalizedName = normalize(entity.name);
	const normalizedTicker = entity.ticker ? normalize(entity.ticker) : '';
	const normalizedNctId = entity.nct_id ? normalize(entity.nct_id) : '';
	const normalizedVariants = entity.name_variants?.map(normalize) || [];

	if (normalizedName === normalizedFilter) return 10;
	if ([normalizedTicker, normalizedNctId, ...normalizedVariants].includes(normalizedFilter)) return 9;

	if (normalizedName.startsWith(normalizedFilter)) return 8;
	if ([normalizedTicker, normalizedNctId, ...normalizedVariants].some(value => value.startsWith(normalizedFilter))) return 7;

	if (normalizedName.includes(normalizedFilter)) return 6;
	if ([normalizedTicker, normalizedNctId, ...normalizedVariants].some(value => value.includes(normalizedFilter))) return 5;

	return 0;
}

function valueVariantFilterRelevance(entity: ValueEntity, filter: string)
{
	if (!filter || filter === '') return 1;
	if (!entity) return 0;
	if (entity.value === filter) return 10;
	if (entity.name_variants && entity.name_variants.includes(filter)) return 7;
	if (entity.value.toLowerCase().includes(filter.toLowerCase())) return 2;
	if (entity.name_variants && entity.name_variants.some(variant => variant.toLowerCase().includes(filter.toLowerCase()))) return 1;
	return 0;
}

export { valueVariantFilterRelevance };
export default variantFilterRelevance;
