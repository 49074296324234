import { PrecomputedValueSet } from '@/components/Enum/NestedSelectDropdownPC';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EnumTypes {
	
}

interface EnumsState
{
	loaded: boolean;
	entities: boolean;
	requested: boolean;
	enums: {
		//Objects
		col_descriptions?: Record<string, string>,
		col_name_variants?: Record<string, string[]>,

		//Hierarchical enums
		indications?: PrecomputedValueSet,
		mechanisms?: PrecomputedValueSet,
		product_types?: PrecomputedValueSet,
		drug_classes?: PrecomputedValueSet,

		//Entities
		drugs?: any[]
		trials?: any[]
		companies?: any[]

		//Otherwise, keys will refer to arrays
		[key: string]: any[] | Record<string, string> | Record<string, string[]> | PrecomputedValueSet |  undefined;
	};
}

const initialState: EnumsState = {
	loaded: false,
	entities: false,
	requested: false,
	enums: {}
};

export const enumsSlice = createSlice({
	name: 'session',
	initialState,
	reducers: {
		setEnums: (state, action: PayloadAction<any>) =>
		{
			//state.sessionData = action.payload;
			return { ...state, enums: action.payload };
		},
		setLoaded: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, loaded: action.payload };
		},
		setEntities: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, entities: action.payload };
		},
		setRequested: (state, action: PayloadAction<boolean>) =>
		{
			return { ...state, requested: action.payload };
		}
	}
});

export const { setEnums, setLoaded, setEntities, setRequested } = enumsSlice.actions;
export type { EnumsState };
export default enumsSlice.reducer;
