const getVisibleElement = (id: string): Element | null  => {
	//With the hiding of elements, we need to find the visible element, because duplicated elements might appear in the dom
	const inputElements = document.querySelectorAll(`[id^="${id}"]`);
	let visibleElement: Element | null = null;
	
	//Find max size, because the visible element will have the biggest size
	let maxSize = 0;
	inputElements.forEach((element) => {
		const rect = element.getBoundingClientRect();
		const size = rect.width * rect.height;
		if(size > maxSize)
		{
			maxSize = size;
			visibleElement = element;
		}
	});

	//console.log("Visible element", visibleElement, 'from id', id);
	return visibleElement;
}

export default getVisibleElement;