import Session from '@/types/Session';
import { User } from '@propelauth/react';

import store from '@/redux/store';
import insertChat from '../mutateSession/insertChat';
import getVisibleElement from '../getVisibleElement';

const anticipateMessageAndResponse = (userMessage: string) =>
{
	const state = store.getState();
	const user: User | null = state.user.user;

	if (!user) return;

	const sessionData: Session | null = state.session.sessionData;

	if (!sessionData) return;

	const chat_idx = state.tab.activeChatIndex;

	//If there's no chat, we can't add a message
	if (chat_idx === -1) return;
	if (sessionData.chats.length === 0) return;

	const chat_id = sessionData.chats[chat_idx].id;

	console.log('Adding message and response to chat: ', chat_idx, chat_id);

	const newActions = [
		{
			action_type: 'contextual_answer',
			data: null,
			message: userMessage,
			action_id: '-1',
			tab_id: chat_id ?? '',
			role_type: 'user',
			role_info: {
				display_name: `${user.firstName} ${user.lastName}`,
				type: 'user',
				user_id: user.userId,
				avatar_url: user.pictureUrl ?? ''
			}
		},
		{
			action_type: 'contextual_answer',
			message: null,
			response_done: false,
			data: {
				status: 'sending'
			},
			action_id: '-1',
			tab_id: chat_id ?? '',
			role_type: 'system',
			role_info: {
				display_name: `Maven Bio`,
				type: 'system',
				user_id: '-1',
				avatar_url: '/icon_white.svg'
			}
		}
	];

	//Insert at the end of the chat:
	const chatActions = [...sessionData.chats[chat_idx].actions, ...newActions];
	const newChat: any = {
		...sessionData.chats[chat_idx],
		actions: chatActions
	};

	insertChat(newChat);

	//Once the message is sent, scroll to end
	const chatContainer = getVisibleElement('chat_idx_0');
	if (chatContainer)
	{
		chatContainer.scrollTo(0, chatContainer.scrollHeight);
	}
};

export default anticipateMessageAndResponse;
